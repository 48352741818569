<template>
  <div class="d-flex justify-space-around" style="border: 0px">
    <v-col cols="2"> <v-card-title id="title"> Filtro</v-card-title></v-col>
    <v-col cols="10">
      <div class="d-flex justify-space-between mt-4">
        <v-autocomplete
          id="inputEmpresa"
          ref="scroll-track-input"
          class="mr-2 primary--text"
          v-model="nrInscIdeempregador"
          :items="userEstablishment"
          label="Empresa"
          item-text="name"
          item-color="primary"
          :item-value="(item) => item.cnpj"
          outlined
          :search-input.sync="search2"
          append-icon="mdi-chevron-down"
          @change="search2 = ''"
          return-object
          dense
          multiple
          @input="(items) => changeFilterRubricas({ prop: 'nrInscIdeempregador', value: items })"
        >
          <template v-slot:selection="{ index }">
            <span v-if="index === 0" class="primary--text mr-n12" id="empresaSelected">
              {{ `${nrInscIdeempregador.length} selecionado${nrInscIdeempregador.length > 1 ? 's' : ''}` }}
            </span>
          </template>
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item
              :style="'max-width: 351px !important;'"
              #default="{ active }"
              v-bind="attrs"
              v-on="on"
              :id="item.cnpj"
            >
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span> {{ item.name }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <div width="350px">
          <v-dialog model="dialog" width="350px">
            <v-date-picker
              :disabled="nrInscIdeempregador.length < 1"
              clearable
              :clear-icon-cb="clearDate"
              v-model="datesFilter"
              type="month"
              range
              @input="(items) => changeFilterRubricas({ prop: 'dates', value: items })"
            ></v-date-picker>

            <template v-slot:activator="{ on }">
              <v-text-field
                autocomplete="off"
                :disabled="nrInscIdeempregador.length < 1"
                :style="
                  nrInscIdeempregador.length < 1
                    ? 'background-color: #e7e7fa !important; height: 40px !important; width: 200px !important;'
                    : 'background-color: #ffffff; width: 200px !important; height: 40px !important;'
                "
                class="mx-1"
                v-on="on"
                id="textPicker"
                label="Período de validade"
                v-model="computeRange"
                append-icon="mdi-calendar"
                dense
                outlined
              >
              </v-text-field>
            </template>
          </v-dialog>

          <div id="date-hint-div">
            <p class="v-application">{{ pickerMessage }}</p>
          </div>
        </div>

        <v-autocomplete
          append-icon="mdi-chevron-down"
          :disabled="nrInscIdeempregador.length < 1 || errorMessage"
          class="mx-2"
          :style="
            nrInscIdeempregador.length < 1
              ? 'background-color: #e7e7fa !important; height: 40px;border: 0px solid #CCCCCC;'
              : 'background-color: #ffffff; height: 40px !important;'
          "
          dense
          v-model="statusFilter"
          outlined
          :items="filterStatusItems"
          multiple
          :search-input.sync="search"
          @change="search = ''"
          id="selectSituation"
          label="Situação"
          @input="(items) => changeFilterRubricas({ prop: 'status', value: items })"
        >
          <template v-slot:item="{ item, attrs, on }" :id="item">
            <v-list-item
              :style="'max-width: 351px !important;'"
              #default="{ active }"
              v-bind="attrs"
              v-on="on"
              :id="item"
            >
              <v-list-item-action>
                {{ item | estados }}
              </v-list-item-action>
            </v-list-item>
          </template>

          <template v-slot:selection="{ index }">
            <span v-if="index === 0" class="primary--text ml-n1" id="inputSelectSituation">
              {{ `${statusFilter.length} selecionado${statusFilter.length > 1 ? 's' : ''}` }}
            </span>
          </template>
        </v-autocomplete>

        <v-btn
          id="applyBtn"
          height="40px"
          color="primary"
          @click="fetchRubricas()"
          class="mx-2"
          :disabled="nrInscIdeempregador.length < 1 || errorMessage"
        >
          Aplicar filtro</v-btn
        >

        <v-btn
          id="clearBtn"
          class="ml-2 primary--text"
          height="40px"
          color="white"
          :disabled="nrInscIdeempregador.length < 1"
          @click="cleanFilters()"
          ><v-icon>mdi-delete-outline</v-icon> Limpar filtro</v-btn
        >
      </div></v-col
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AdminServices from '@/services/adminServices.js';
import sortBy from 'lodash/sortBy';
export default {
  name: 'FiltroRubrica',

  data() {
    return {
      filter: {
        dates: [],
        status: [],
      },
      search: null,
      search2: null,
      validOrNot: true,
      notValidDateFim: false,

      notValidDate: false,
      invalidForm: false,
      pickerMessage: '',
      errorMessage: false,
      userEstablishment: [],
      filterStatusItems: [
        'PENDENTE_DE_VALIDACAO',
        'CONCILIADA',
        'PENDENTE_APROVACAO',
        'PENDENTE_PARAMETRIZACAO',
        'DIVERGENTE',
        'REPROVADA',
      ],
    };
  },
  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      statusFilter: 'esocial/esocialMain/getStatusFilter',
      nrInscIdeempregador: 'esocial/esocialMain/getNrInscIdeempregador',
      datesFilter: 'esocial/esocialMain/getDates',
    }),

    isCompanySelected() {
      if (this.nrInscIdeempregador.length < 0) {
        return true;
      } else {
        return false;
      }
    },

    computeRange: {
      get() {
        if (this.datesFilter == undefined || this.datesFilter.length == 0) {
          return '';
        }
        let from = this.datesFilter[0];
        this.filter.inivalid_iderubrica = from;
        let to = this.datesFilter[1];
        this.filter.fimvalid_iderubrica = to;
        let fromMilis = this.getMillisecondsDate(from);
        let toMilis = this.getMillisecondsDate(to);

        if (this.isInvalidDates(fromMilis, toMilis)) {
          if (this.isRevertedDates(fromMilis, toMilis)) {
            this.pickerMessage = 'Preencha primeiro a data mais antiga';
            this.errorMessage = true;
          } else {
            this.pickerMessage = '';
            this.errorMessage = false;
          }
          // document.querySelector('#date-hint-div').classList.add('v-messages__message');
          this.disabledInputs = true;
        }
        this.filter.datesFormat = [this.formatDate(from), this.formatDate(to)];

        return this.filter.datesFormat.join(' a ');
      },
    },
  },

  created() {
    this.getCompanies();
  },

  methods: {
    ...mapActions('esocial/esocialMain/', [
      'changeFilter',
      'fetchDebounce',
      'changeFilterRubricas',
      'fetch',
      'cleanPersistentFilter',
      'clearProp',
      'setPagination',
      'fetchRubricas',
    ]),
    clearDate() {
      this.filter.dates = [];
    },
    updateData() {
      changeFilterRubricas;
    },
    cleanFilters() {
      this.cleanPersistentFilter();
    },
    isInvalidDates(from, to) {
      let sixtyDays = 60;
      return !from || !to || this.isRevertedDates(from, to) || (to - from) / 1000 / 60 / 60 / 24 > sixtyDays;
    },
    isRevertedDates(from, to) {
      return to < from;
    },
    getMillisecondsDate(dateString) {
      var date = new Date(dateString);
      return date.getTime();
    },
    fixPosition() {
      const menu = document.getElementById('app').nextElementSibling;
      if (!menu) return;
      const input = this.$refs['scroll-track-input'].$el.getBoundingClientRect();
      menu.style.top = input.bottom - 50 + 'px';
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split('-');
      return `${month}/${year}`;
    },
    async getCompanies() {
      let { data } = await AdminServices.userEstablishment.get(this.usuario.id);

      let userEstablishments = data.map((item) => {
        return { name: item.establishment.socialName, cnpj: item.establishment.details.cnpj };
      });

      userEstablishments = sortBy(userEstablishments, ['name']);
      let establishments = userEstablishments.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

      this.userEstablishment = establishments;
    },
  },
};
</script>

<style>
#title {
  margin-left: -12px;
  margin-top: -4px;
  font-size: 20px !important;
  font-weight: bold !important;
  color: #182026 !important;
}

#clearBtn:enabled {
  font-size: 16px !important;
  font-weight: bold !important;
  border-radius: 4px !important;
}
#clearBtn:disabled {
  font-size: 16px !important;
  color: #8686a2 !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  background-color: #e7e7fa !important;
}
#applyBtn:enabled {
  font-size: 16px !important;
  font-weight: bold !important;
  border-radius: 4px !important;
}

#applyBtn:disabled {
  font-size: 16px !important;
  color: #8686a2 !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  background-color: #e7e7fa !important;
}

#chip {
  color: #2a1c4f;
  background-color: #f7f7fd;
  border-color: #e7e7fa;
  border-radius: 4px;
  border-width: 1px;
  font-weight: 'regular';
  font-size: 16px;
}

#textPicker:disabled {
  color: #8686a2 !important;
  background-color: #e7e7fa !important;
}
#textPicker {
  color: var(--v-primary-base);
}

/*  */
</style>
